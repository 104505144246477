<template>
  <div>
    <v-row>
      <!-- vertical statistics card -->
      <v-col
        v-if="$can('manage_system', 'System') || $can('list_display', 'Display')"
        cols="6"
        sm="4"
        md="3"
      >
        <statistics-card-vertical
          :stat-title="activeDisplays.statTitle"
          :color="activeDisplays.color"
          :icon="activeDisplays.icon"
          :statistics="activeDisplaysNumber"
        ></statistics-card-vertical>
      </v-col>
      <v-col
        v-if="$can('manage_system', 'System') || $can('list_location', 'Location')"
        cols="6"
        sm="4"
        md="3"
      >
        <statistics-card-vertical
          :stat-title="activeLocations.statTitle"
          :color="activeLocations.color"
          :icon="activeLocations.icon"
          :statistics="activeLocationsNumber"
        ></statistics-card-vertical>
      </v-col>
      <v-col
        v-if="$can('manage_system', 'System') || $can('list_display', 'Display')"
        cols="6"
        sm="4"
        md="3"
      >
        <statistics-card-vertical
          :stat-title="displaysInStandby.statTitle"
          :color="displaysInStandby.color"
          :icon="displaysInStandby.icon"
          :statistics="standbyDisplaysNumber"
        ></statistics-card-vertical>
      </v-col>
      <v-col
        v-if="$can('manage_system', 'System') || $can('list_display', 'Display')"
        cols="6"
        sm="4"
        md="3"
      >
        <statistics-card-vertical
          :stat-title="mirroredDisplays.statTitle"
          :color="mirroredDisplays.color"
          :icon="mirroredDisplays.icon"
          :statistics="mirroredDisplaysNumber"
        ></statistics-card-vertical>
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="3"
      >
      </v-col>
    </v-row>
    <v-row
      v-if="
        $can('manage_system', 'System') ||
          $can('manage_display', 'Display') ||
          $can('manage_location', 'Location')
      "
    >
      <v-col>
        <v-card color="text-center">
          <v-card-text class="d-flex flex-column justify-center align-center">
            <v-avatar
              color="primary"
              class="v-avatar-light-bg primary--text mt-10"
              icon
              size="50"
            >
              <v-icon
                size="2rem"
                color="primary"
              >
                {{ icons.mdiPlus }}
              </v-icon>
            </v-avatar>
            <h6 class="text-xl mt-4 font-weight-medium">
              {{ $t('Create') }}
            </h6>
          </v-card-text>
          <v-card-text>
            <v-btn
              v-if="$can('manage_system', 'System') || $can('manage_location', 'Location')"
              color="primary"
              class="mt-4 mr-4"
              :to="{ name: 'apps-locations-list-add' }"
            >
              {{ $t('Add Location') }}
            </v-btn>
            <v-btn
              v-if="$can('manage_system') || $can('manage_display')"
              color="primary"
              class="mt-4"
              :to="{ name: 'apps-locations-display' }"
            >
              {{ $t('Add Display') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StatisticsCardVertical from '@/@core/components/statistics-card/StatisticsCardVertical.vue'

// eslint-disable-next-line object-curly-newline
import { mdiBed, mdiMapMarker, mdiMonitor, mdiContentCopy, mdiPlus } from '@mdi/js'
import axios from '@axios'

export default {
  components: {
    StatisticsCardVertical,
  },
  data() {
    return {
      info: null,
      activeDisplaysNumber: 0,
      standbyDisplaysNumber: 0,
      mirroredDisplaysNumber: 0,
      activeLocationsNumber: 0,

    }
  },
  mounted() {
    axios
      .get('/displays/statistic')
      .then(response => {
        this.activeDisplaysNumber = response.data.data.activeDisplays
        this.standbyDisplaysNumber = response.data.data.standbyDisplays
        this.mirroredDisplaysNumber = response.data.data.mirroredDisplays
      })
      .catch()
    axios
      .get('/location/statistic')
      .then(response => {
        this.activeLocationsNumber = response.data.data.activeLocations
      })
  },
  setup() {
    // vertical card options
    const activeDisplays = {
      statTitle: 'Active displays',
      icon: mdiMonitor,
      color: 'primary',
      statistics: '862',
    }

    const activeLocations = {
      statTitle: 'Active Locations',
      icon: mdiMapMarker,
      color: 'secondary',
      statistics: '$25.6k',
    }

    const displaysInStandby = {
      statTitle: 'Displays in standby',
      icon: mdiBed,
      color: 'success',
      statistics: '$95.2k',
    }

    const mirroredDisplays = {
      statTitle: 'Mirrored displays',
      icon: mdiContentCopy,
      color: 'error',
      statistics: '44.10k',
    }

    return {
      // horizontal card
      activeDisplays,
      activeLocations,
      displaysInStandby,
      mirroredDisplays,

      // icons
      icons: {
        mdiPlus,
      },
    }
  },
}
</script>
